/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Route, Switch } from 'react-router-dom';

import TagManager from 'react-gtm-module';

import APP_ROUTING from 'constants/appRouting';

import CompanySignUpConfirmation from 'components/CompanySignUpConfirmation';
import Settings from 'containers/Settings';
import NoMatchPage from 'components/NoMatchPage';

import ErrorBoundary from 'helpers/ErrorBoundary';

import Router from 'wrapperComponents/BrowserRouter';

// eslint-disable-next-line import/no-named-as-default
import PrivateRoute from 'containers/PrivateRoute';
// eslint-disable-next-line import/no-named-as-default
import App from 'containers/App';
// eslint-disable-next-line import/no-named-as-default
import Callback from 'containers/Callback';
import CallbackBankin from 'containers/Bank/CallbackBankin';
import Logout from 'containers/Logout';
import Signup from 'containers/AppSignup';
// eslint-disable-next-line import/no-named-as-default
import Home from 'containers/Home';
import ExpensesPage from 'containers/ExpensesPage/Main';
import ExpenseForm from 'containers/ExpensesPage/NewForm';
import ExpensePdf from 'containers/ExpensesPage/Pdf';

import PurchaseViewContainer from 'containers/purchase/view/PurchaseViewContainer';
// eslint-disable-next-line import/no-named-as-default
import ExpensesList from 'containers/ExpensesPage/List';
import AdvanceExpenseForm from 'containers/AdvanceExpenses/Form';
// eslint-disable-next-line import/no-named-as-default
import InternalActivitiesList from 'containers/InternalActivities/List';
// eslint-disable-next-line import/no-named-as-default
import ClientsList from 'containers/Clients/List';
import ClientImport from 'containers/Clients/ImportFile';
import ClientProjectsList from 'containers/ClientProjects/List';
// eslint-disable-next-line import/no-named-as-default
import UsersList from 'containers/UsersPage/List';
import Account from 'containers/UserProfile';
// eslint-disable-next-line import/no-named-as-default
import InvoicesList from 'containers/Invoice/List';
// import IncomeView from 'containers/Invoice/Main';
import InvoiceForm from 'containers/Invoice/Form';
import InvoicePdf from 'containers/Invoice/Pdf';
// eslint-disable-next-line import/no-named-as-default
import ItemsList from 'containers/Item/List';
import ImportItemList from 'containers/Item/ImportItemList';
// eslint-disable-next-line import/no-named-as-default
import RolesList from 'containers/Roles/List';
// eslint-disable-next-line import/no-named-as-default
import RoleForm from 'containers/Roles/Form';
// eslint-disable-next-line import/no-named-as-default
import ProviderList from 'containers/ProvidersPage/List';
import ProviderImport from 'containers/ProvidersPage/ImportFile';
// eslint-disable-next-line import/no-named-as-default
import CompanyForm from 'containers/Company';
// import BookKeepingPage from 'containers/BookKeeping';
import BookKeepingExports from 'containers/BookKeeping/Exports';
import BookKeepingExportsTest from 'containers/BookKeeping/Exports/ExportTest';
import RevenueForm from 'containers/revenue/Form';

// import CardContainer from 'containers/CardContainers/CardContainer';
import TreezorContainer from 'containers/CardContainers/Treezor';
import BankAccounts from 'containers/Bank/BankAccounts';
import CreateOnlyNewTreezorUser from 'containers/CardContainers/Treezor/userFormContainer/CreateNewUserAfterOnboarding/createOnlyNewTreezorUser';

import TemplateList from 'containers/Template/List';
import TemplateForm from 'containers/Template/Form';

import EmailTemplateList from 'containers/EmailTemplate/List';

import CustomerDocumentPage from 'containers/Customer/Document';

import Login from 'containers/AuthPage/Login';

import { isAuthenticated } from 'actions/AuthActions';
import { changeLanguage } from 'actions/LanguageActions';

import SEPARequestList from 'containers/BankBBF/pages/SEPARequestList';
import SEPAViewRequestForm from 'containers/BankBBF/pages/SEPAViewRequestForm';
import SEPARequestForm from 'containers/BankBBF/pages/SEPARequestForm';
import BankBBFAccount from 'containers/BankBBF/pages/BankBBFAccounts';
import BankBBFDeletePayout from 'containers/BankBBF/pages/BankBBFDeletePayout';
import TransferPage from 'containers/BankBBF/pages/TransferPage';
import WalletDeletePage from 'containers/BankBBF/pages/WalletDeletePage';

import PurchaseForm from 'containers/purchase/PurchaseForm';
import PurchaseList from 'containers/purchase/list/PurchaseList';

import DashBoardExpense from 'containers/DashBoardExpense';
import PaypalPage from 'containers/Customer/Paypal/index';
import CompanyOwners from 'containers/CompanyOwners';
import AddOwnerContainer from 'containers/CompanyOwners/components/AddOwnerContainer';
import CardContainer from 'containers/CardContainers/CardContainer';
import SelectCards from 'containers/CardContainers/CardContainer/SelectCards';
import HipayPage from 'containers/Customer/HiPay';
import TreezorPending from 'components/CardComponents/Treezor/TreezorDocsPending/treezorDocsPending';
import DocInvalid from 'containers/CardContainers/Treezor/docNeed';
import DocNeeded from 'containers/CardContainers/Treezor/OneMoreDocNeeded';
import CardList from 'components/CardComponents/CardList';
import RevenueDetail from 'components/RevenueForms/SimpleForm/RevenueDetail';
import CardDetail from 'containers/CardContainers/CardDetail';
import BankAccountTransactions from 'containers/Bank/BankAccountTransactions';
import PDFManagement from 'containers/PDFManagement';
import PDFSplit from 'containers/PDFManagement/Split';
import PDFHistory from 'containers/PDFHistory';
import PDFItem from 'containers/PDFHistory/PDFItem';
import PDFArchive from 'containers/PDFArchive';
import Deposit from 'containers/Deposit';
import ArchivedDeposit from 'containers/Deposit/ArchivedDeposit';
import Projects from 'containers/Project';

import CreditNoteBankInfo from 'containers/CreditNoteBankInfo';
import Onboarding from 'containers/Onboarding';
import StripePricingTablePage from 'containers/StripePricingTable';
// import BankBBFAccount from './containers/BankBBF/BankBBFAccounts';
import Subscription from 'containers/Subscription/List';
import Register from 'containers/AuthPage/Register';
import { APP_USE_TREEZOR } from 'constants/treezor';
import Categories from 'containers/Categories/List';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptions';
import AnalyticalFields from 'containers/AnalyticalFields';
import AgentIA from 'containers/AgentIA';
import BankTransfer from './containers/Bank/BankTransfer';
import Transaction from './containers/Transaction';
import SelectSubscription from './containers/SelectSubscription';

class MyRouter extends Component {
  constructor() {
    super();
    this.state = {
      screenName: '',
      activitiesTreezor: false,
    };

    this.setScreenName = this.setScreenName.bind(this);
  }

  componentDidMount() {
    const {
      loggedUser: { user },
      appInitialized,
      loggedUserCompany,
    } = this.props;
    const isActivateTreezor = Boolean(
      loggedUserCompany?.company?.isActivateTreezor
    );
    this.setState({
      activitiesTreezor: APP_USE_TREEZOR && isActivateTreezor,
    });

    if (user && user.language && !appInitialized) {
      this.props.changeLanguage(user.language);
    }
  }

  componentDidUpdate(preProps) {
    const {
      loggedUser: { user },
      appInitialized,
    } = this.props;
    if (user !== preProps.loggedUser.user) {
      if (user && user.language && !appInitialized) {
        this.props.changeLanguage(user.language);
      }
    }
  }

  setScreenName(screenName) {
    this.setState({ screenName });
  }

  render() {
    return (
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <App setScreenName={this.setScreenName} {...props} />
              )}
            />
            <Route
              exact
              path="/register"
              render={(props) => (
                <Register setScreenName={this.setScreenName} {...props} />
              )}
            />
            <Route
              exact
              path="/login"
              render={(props) => <Login {...props} />}
            />

            <Route
              exact
              path={APP_ROUTING.ONBOARDING}
              render={(props) => <Onboarding {...props} />}
            />
            <Route
              exact
              path="/select-subscription/:companyId"
              render={(props) => <SelectSubscription {...props} />}
            />

            <Route
              exact
              path="/stripe-pricing"
              render={(props) => <StripePricingTablePage {...props} />}
            />

            <Route
              exact
              path="/customer/document/:id/:clientId"
              render={(props) => (
                <CustomerDocumentPage
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <Route
              exact
              path="/customer/paypal/:id/:clientId/:paypalClientId"
              render={(props) => (
                <PaypalPage {...props} setScreenName={this.setScreenName} />
              )}
            />
            <Route
              exact
              path="/customer/hipay/:status"
              render={(props) => (
                <HipayPage {...props} setScreenName={this.setScreenName} />
              )}
            />
            <Route
              exact
              path="/signup"
              render={(props) => (
                <Signup {...props} setScreenName={this.setScreenName} />
              )}
            />
            <Route
              exact
              path="/signup-confirm"
              render={(props) => (
                <CompanySignUpConfirmation
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <Route
              exact
              path="/callback"
              render={(props) => (
                <Callback {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/treezor-onboarding"
              render={(props) => <TreezorContainer {...props} />}
            />
            <PrivateRoute
              exact
              path="/treezorPending"
              render={(props) => <TreezorPending {...props} />}
            />
            <PrivateRoute
              exact
              path="/treezorDocumentsInvalids"
              render={(props) => <DocInvalid {...props} />}
            />
            <PrivateRoute
              exact
              path="/treezorDocumentNeeded"
              render={(props) => <DocNeeded {...props} />}
            />
            <PrivateRoute
              exact
              path="/cards"
              render={(props) => <CardList {...props} />}
            />
            <PrivateRoute
              exact
              path="/NewTreezorUser/:id"
              render={(props) => <CreateOnlyNewTreezorUser {...props} />}
            />
            <PrivateRoute
              exact
              path="/cards/select/:id"
              render={(props) => <SelectCards {...props} />}
            />
            <PrivateRoute
              path="/card/virtual/new"
              render={(props) => <CardContainer virtual={true} {...props} />}
            />
            <PrivateRoute
              path="/card/physical/new"
              render={(props) => <CardContainer virtual={false} {...props} />}
            />
            <PrivateRoute
              exact
              path="/card/:id"
              render={(props) => <CardDetail {...props} />}
            />
            <PrivateRoute
              exact
              path="/bank-bbf/accounts"
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              restrictedFeature={!this.state.activitiesTreezor}
              render={(props) => (
                <BankBBFAccount {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/bank-bbf/wallet/delete"
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              render={(props) => (
                <WalletDeletePage
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/bank-bbf/delete/payout"
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              render={(props) => (
                <BankBBFDeletePayout
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank-bbf/sepa/request"
              render={(props) => (
                <SEPARequestForm
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank-bbf/sepa/request/view"
              render={(props) => (
                <SEPAViewRequestForm
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank-bbf/sepa/requests"
              render={(props) => (
                <SEPARequestList
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank-bbf/transfer"
              render={(props) => (
                <TransferPage {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              exact
              path="/bank/account/:id"
              render={(props) => (
                <BankAccountTransactions
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/callback/bankin"
              render={(props) => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const responseFromBankinApi = {
                  success: urlParams.get('success'),
                  user_uuid: urlParams.get('user_uuid'),
                  step: urlParams.get('step'),
                  item: urlParams.get('item_id'),
                };
                return (
                  <CallbackBankin
                    {...props}
                    responseFromBankinApi={responseFromBankinApi}
                    setScreenName={this.setScreenName}
                  />
                );
              }}
            />
            <Route
              path="/logout"
              render={(props) => (
                <Logout {...props} setScreenName={this.setScreenName} />
              )}
            />
            <Route
              path="/companyOwners"
              render={(props) => <CompanyOwners {...props} />}
            />
            <Route
              path="/addOwner"
              render={(props) => <AddOwnerContainer {...props} />}
            />

            <PrivateRoute
              exact
              path="/bank/accounts"
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              render={(props) => (
                <BankAccounts {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank/transfer"
              render={(props) => (
                <BankTransfer {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              subscriptionTypes={[
                SUBSCRIPTION_TYPE.PAID_TIER,
                SUBSCRIPTION_TYPE.CUSTOM_TIER,
              ]}
              path="/bank/account/:id"
              render={(props) => (
                <BankAccountTransactions
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/home"
              shouldFillMandatoryData={false}
              render={(props) => (
                <Home {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/expenses"
              restrictedFeature="userHasExpenseAccess"
              render={(props) => (
                <ExpensesPage {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/expenses/new"
              restrictedFeature="userCanCreateExpense"
              render={(props) => (
                <ExpenseForm {...props} setScreenName={this.setScreenName} />
              )}
            />

            <PrivateRoute
              exact
              path="/purchases/new"
              restrictedFeature="userCanCreateExpense"
              render={(props) => (
                <PurchaseForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/revenue/new"
              // restrictedFeature="userCanCreateRevenue"
              render={(props) => (
                <RevenueForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanCreateExpense"
              path="/purchases/edit/:id"
              // formerly path=/purchases/invoice/edit/:id"
              render={(props) => (
                <PurchaseViewContainer
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf"
              render={(props) => (
                <PDFManagement {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf/split"
              render={(props) => (
                <PDFSplit {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf/:id"
              render={(props) => (
                <PDFItem {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf/item/:id"
              render={(props) => (
                <PDFHistory {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/pdf/item/:id/archive"
              render={(props) => (
                <PDFArchive {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/expenses/edit/:id"
              restrictedFeature={{
                $or: ['userCanUpdateExpense', 'userCanReadExpense'],
              }}
              render={(props) => (
                <ExpenseForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanCreateAdvanceExpense"
              path="/expenses/advance/new"
              render={(props) => (
                <AdvanceExpenseForm
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature={{
                $or: [
                  'userCanUpdateAdvanceExpense',
                  'userCanReadAdvanceExpense',
                ],
              }}
              path="/expenses/advance/edit/:id"
              render={(props) => (
                <AdvanceExpenseForm
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature={{
                $or: ['userHasAdvanceExpenseAccess', 'userHasExpenseAccess'],
              }}
              path="/expenses/list"
              render={(props) => (
                <ExpensesList
                  {...props}
                  setScreenName={(screenName) => {
                    this.setState({ screenName });
                  }}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature={{
                $or: ['userHasAdvanceExpenseAccess', 'userHasExpenseAccess'],
              }}
              path="/dashboard/overviewExpenses"
              render={() => <DashBoardExpense />}
            />
            <PrivateRoute
              exact
              restrictedFeature={{
                $or: ['userHasAdvanceExpenseAccess', 'userHasExpenseAccess'],
              }}
              path="/purchases/list"
              render={(props) => (
                <PurchaseList
                  {...props}
                  setScreenName={(screenName) => {
                    this.setState({ screenName });
                  }}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasBookKeepingAccess"
              path="/bookKeeping/export"
              render={(props) => (
                <BookKeepingExports
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasBookKeepingAccess"
              path="/bookKeeping/export/test"
              render={(props) => (
                <BookKeepingExportsTest
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasAgentAccess"
              path="/agent/chat/:id"
              render={(props) => (
                <AgentIA {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasAgentAccess"
              path="/agent/chat"
              render={(props) => (
                <AgentIA {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/invoices/list"
              restrictedFeature="userHasInvoiceAccess"
              render={(props) => (
                <InvoicesList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/invoices/new"
              restrictedFeature="userCanCreateInvoice"
              render={(props) => (
                <InvoiceForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/invoices/edit/:id"
              restrictedFeature={{
                $or: ['userCanReadInvoice', 'userCanUpdateInvoice'],
              }}
              render={(props) => (
                <InvoiceForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/invoices/pdf/:id/:type"
              restrictedFeature="userCanReadInvoice"
              render={(props) => (
                <InvoicePdf {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              shouldFillMandatoryData={false}
              path="/account"
              render={(props) => (
                <Account {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/settings"
              restrictedFeature="userHasCompanySettingsAccess"
              render={(props) => (
                <Settings {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/settings/internal-activities"
              render={(props) => (
                <InternalActivitiesList
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasClientAccess"
              path="/settings/clients"
              render={(props) => (
                <ClientsList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasClientAccess"
              path={APP_ROUTING.IMPORT_COMPANIES}
              render={(props) => (
                <ClientImport
                  {...props}
                  setScreenName={this.setScreenName}
                  isCompany
                  screenType="IMPORT COMPANIES"
                />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasClientAccess"
              path={APP_ROUTING.IMPORT_INDIVIDUALS}
              render={(props) => (
                <ClientImport
                  {...props}
                  setScreenName={this.setScreenName}
                  isCompany={false}
                  screenType="IMPORT INDIVIDUALS"
                />
              )}
            />
            <PrivateRoute
              exact
              path="/settings/projects"
              render={(props) => (
                <ClientProjectsList
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/settings/users"
              restrictedFeature="userHasUserManagementAccess"
              render={(props) => (
                <UsersList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/settings/providers"
              render={(props) => (
                <ProviderList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path={APP_ROUTING.ITEM_LIST}
              restrictedFeature="userHasItemAccess"
              render={(props) => (
                <ItemsList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path={APP_ROUTING.IMPORT_ITEM_LIST}
              restrictedFeature="userHasItemAccess"
              render={(props) => (
                <ImportItemList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasUserRoleManagementAccess"
              path="/settings/roles"
              render={(props) => (
                <RolesList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanCreateUserRoleManagement"
              path="/settings/roles/new"
              render={(props) => (
                <RoleForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanUpdateUserRoleManagement"
              path="/settings/roles/edit/:id"
              render={(props) => (
                <RoleForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              shouldFillMandatoryData={false}
              path="/settings/company"
              render={(props) => (
                <CompanyForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userHasTemplateAccess"
              path="/settings/template"
              render={(props) => (
                <TemplateList {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanCreateTemplate"
              path="/settings/template/new"
              render={(props) => (
                <TemplateForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              restrictedFeature="userCanUpdateTemplate"
              path="/settings/template/edit/:id"
              render={(props) => (
                <TemplateForm {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              // restrictedFeature="userCanUpdateEmailTemplate"
              path="/settings/emailTemplates"
              render={(props) => (
                <EmailTemplateList
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              // restrictedFeature="userCanUpdateEmailTemplate"
              path="/settings/subscriptions"
              render={(props) => (
                <Subscription {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              shouldFillMandatoryData={false}
              path="/settings/categories"
              render={(props) => (
                <Categories {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              shouldFillMandatoryData={false}
              path="/settings/analytical-fields"
              render={(props) => (
                <AnalyticalFields
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              // restrictedFeature="userCanUpdateEmailTemplate"
              path={APP_ROUTING.IMPORT_PROVIDER}
              render={(props) => (
                <ProviderImport {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/transaction/:type/:id"
              render={(props) => (
                <Transaction {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/revenue/:id"
              render={(props) => (
                <RevenueDetail {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/deposit"
              render={(props) => (
                <Deposit {...props} setScreenName={this.setScreenName} />
              )}
            />
            <PrivateRoute
              exact
              path="/deposit/archive"
              render={(props) => (
                <ArchivedDeposit
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/projects"
              render={(props) => (
                <Projects {...props} setScreenName={this.setScreenName} />
              )}
              restrictedFeature="userHasProjectAccess"
            />
            <Route
              path="/refund-bank-info"
              render={(props) => (
                <CreditNoteBankInfo
                  {...props}
                  setScreenName={this.setScreenName}
                />
              )}
            />
            <Route
              render={(props) => (
                <NoMatchPage {...props} setScreenName={this.setScreenName} />
              )}
            />
          </Switch>
        </Router>
      </ErrorBoundary>
    );
  }
}

MyRouter.propTypes = {
  loggedUser: PropTypes.object,
  loggedUserCompany: PropTypes.object,
  isAuthenticated: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func,
  appInitialized: PropTypes.bool,
};

function mapStateToProps({ loggedUser, appInitialized, loggedUserCompany }) {
  return { loggedUser, appInitialized, loggedUserCompany };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ isAuthenticated, changeLanguage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRouter);
